



















  import Vue from 'vue'
  import { Component, Prop } from 'nuxt-property-decorator'
  import LazyHydrate from 'vue-lazy-hydration'
  import { namespace } from 'vuex-class'

  const categories = namespace('categories')
  const layout = namespace('layout')

  @Component({
    components: {
      OneBaseHomePage: () => import('~/components/molecules/bases/OneBaseHomePage.vue'),
      OneCarousel: () => import('~/components/molecules/carousels/OneCarousel.vue'),
      OneCmsCategoriesList: () => import('~/components/organisms/list/OneCmsCategoriesList.vue'),
      OneIconLoader: () => import('~/components/molecules/icons/OneIconLoader.vue'),
      LazyHydrate,
    },
  })
  export default class OneCmsCategoriesListWithBanner extends Vue {
    name: string = 'one-cms-categories-list-with-banner'

    @Prop({
      type: Array,
      required: true,
    })
    readonly slides!: Array<string>

    @categories.Getter sortedMainCategories: any
    @categories.Getter getCategory: any
    @layout.Mutation('TOGGLE_BACKDROP') backdropRun: any

    togglingCategoriesMenuHandler: any = null

    get categories(): Array<string> {
      return this.sortedMainCategories.map(
        (category: string): string => this.getCategory(category).name,
      )
    }

    showBackdrop() {
      clearTimeout(this.togglingCategoriesMenuHandler)
      this.togglingCategoriesMenuHandler = setTimeout(() => {
        this.backdropRun(true)
      }, 150)
    }

    hideBackdrop() {
      clearTimeout(this.togglingCategoriesMenuHandler)
      this.backdropRun(false)
    }

    destroyed() {
      this.hideBackdrop()
    }
  }
